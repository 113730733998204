import React from "react";
import {default as numeral} from 'numeral';
import { message } from 'antd';
import { useLocation } from "react-router-dom";
import { Utils, checkTime, RELEASE_TIME, reduceAddress} from "../../utils/utils";
import logo from "../../media/logo1.png";
import telegram from "../../media/tg.png";
import "./Home.scss";
import Countdown from "../../components/Countdown/Countdown";
import USDT_ABI from "../../utils/usdtABI.json";

const USDT_ADDRESS = '0x55d398326f99059fF775485246999027B3197955';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "xxx",
      input: 0,
      blinkMyMiners: false,
      blinkMyEarns: false,
      myEarnsStart: 0,
      myEarns:this.props.myEarns,
      isApproved: false,
      contractBalance: 0,
      timeRemaining: 0,
      winner: "",
    };

    this.render = this.render.bind(this);
  }


  componentDidMount() {
    this.checkApproval();
    this.fetchContractData(); // Fetch initial contract data
  
    // Create separate interval IDs for each function
    this.contractDataIntervalId = setInterval(this.fetchContractData, 2000); // Update every 2 seconds
    this.approvalCheckIntervalId = setInterval(this.checkApproval, 5000); // Update every 5 seconds
  }
  
  componentWillUnmount() {
    // Clear both intervals on unmount
    clearInterval(this.contractDataIntervalId);
    clearInterval(this.approvalCheckIntervalId);
  }
  

  checkApproval = async () => {
    // Check if the wallet is connected before proceeding
    if (this.props.isWalletConnected) {
      console.log("Wallet is not connected. Skipping approval check.");
      this.setState({ isApproved: false });
      return; // Exit the function if the wallet is not connected
    }
  
    try {
      const usdtContract = new Utils.web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
      const allowance = await usdtContract.methods.allowance(this.props.address, Utils.tetherbomb._address).call();
      console.log("Allowance:", allowance);
  
      // Set the minimum required allowance as a string
      const minAllowance = '1000000000000000000'; // 10 USDT
  
      // Check if the allowance is greater than the minimum required allowance
      const isApproved = Number(allowance) > Number(minAllowance);
      console.log("Is Approved:", isApproved);
  
      this.setState({ isApproved });
    } catch (error) {
      console.error("Error checking approval:", error);
    }
  };
  
  fetchContractData = async () => {
    try {
      const usdtContract = new Utils.web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
      
      // Fetch the contract balance (pot size)
      const balance = await usdtContract.methods.balanceOf(Utils.tetherbomb._address).call();
      const contractBalance = Utils.web3.utils.fromWei(balance, 'ether'); // Convert from wei to ether (or USDT)
      
      // Fetch the last deposit time and countdown duration
      const lastDepositTime = await Utils.tetherbomb.methods.lastDepositTime().call();
      const countdownDuration = await Utils.tetherbomb.methods.countdownDuration().call();
      
      // Calculate time remaining using regular numbers
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      const timeElapsed = currentTime - Number(lastDepositTime); // Convert to number for arithmetic
      const timeLeft = Math.max(Number(countdownDuration) - timeElapsed, 0); // Ensure non-negative

      const winner = await Utils.tetherbomb.methods.lastDepositor().call();
  
      // Update the state with the fetched data
      this.setState({ winner, contractBalance, timeRemaining: timeLeft }); // State will be updated with timeLeft as Number
    } catch (error) {
      console.error("Error fetching contract data:", error);
    }
  };
  


  componentDidUpdate = (prevProps)=>{
    if(this.props.myMiners !== prevProps.myMiners){
      this.setState({blinkMyMiners: true},()=>{
        setTimeout(()=>{
          this.setState({
            blinkMyMiners: false
          })
        }, 2000)
      })
    }

    if(this.props.myEarns !== prevProps.myEarns){
      this.setState({
        myEarns: this.props.myEarns,
        blinkMyEarns: true
      },()=>{
        setTimeout(()=>{
          this.setState({
            myEarnsStart: this.props.myEarns,
            blinkMyEarns: false
          })
        }, 3000)
      })
    }
  }
  render() {
  //  const { address, winner, timeRemaining, contractBalance, nativeBalance, userUSDT} = this.props;
  const { userUSDT, isApproved} = this.props;

    return (
      <div className="home">
  <div className="container">
    <div className="header">
      <img src={logo} alt="logo" className="logo" />
      
    </div>
    <div class="logoText">Tether Bomb</div> 

  {/*  <div className={`w3m-button ${!this.props.isWalletConnected ? 'w3m-button-background' : ''}`}>
  <w3m-button />
</div>
*/}




    {
      checkTime() ? null :
        <div className={"countdownWrapper"}>
          <div>
            <Countdown date={RELEASE_TIME} />
          </div>
          <div>{RELEASE_TIME}</div>
        </div>
    }

    <div className={"mainContent"}>
      <div className={"box leftBox"}>
      <div className={"dataRow1"}>
  <div className={"name"}>Pot Size:</div>
  <div className={"value"}>{numeral(this.state.contractBalance).format('0,0.[00]')} USDT (BEP-20)</div>
</div>
<div className={"dataRow1"}>
  <div className={"name"}>Time Left:</div>
  <div className={"value"}>{numeral(this.state.timeRemaining).format('0,0.[0000]')} Seconds</div>
</div>

        <div className={"dataRow1"}>
          <div className={"name"}>
            Winner:
          </div>
          <div className={this.state.blinkMyMiners ? "value blink_me" : "value"}>
            {reduceAddress(this.state.winner)}
          </div>
        </div>
        <div className={"dataRow1"}>
          <div className={"name"}>
            Your Wallet:
          </div>
          <div className={"value"}>
            {numeral(userUSDT).format('0,0.[0000]')} USDT (BEP-20)
          </div>
        </div>



        <div className="buttonContainer">
        <div>
        <>
        <div className="buttonContainer">
          <div
    className="buyButton"
    style={{
      backgroundColor: isApproved ? '#FFFFF' : '#FFFFFF', // Disabled if approved
      color: isApproved ? '#999999' : '#26A17B',
      cursor: isApproved ? 'pointer' : 'pointer',
    }}
    onClick={() => {

        this.approve(); // Only calls approve if isApproved is false

    }}
  >
    {isApproved ? 'APPROVE ONCE' : 'APPROVE ONCE'}
  </div>



          <div
            className="buyButton"
            style={{
              backgroundColor: isApproved ? '#CCCCCC' : '#FFFFFF', // Disabled if approved
              color: isApproved ? '#999999' : '#26A17B',
              cursor: isApproved ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {
              if (!isApproved) {
                this.buy(); // Only calls buy() if isApproved is false
              }
            }}
          >
            {isApproved ? 'PLAY ($5)' : 'PLAY ($5)'}
          </div>
          </div>
          {!this.props.isWalletConnected && (
        <div className={`w3m-button ${this.props.isWalletConnected ? 'w3m-button-background' : ''}`}>
          <w3m-button />
        </div>
      )}
        </>
    </div>
</div>

      </div>

      <div className={"box rightBox"}>
        <div className={"contractInfo"}>
          <div className={"data"}>
            <h1>Project Details</h1>
            <p className="slogan">
              Every $5 ticket resets the timer to 15 minutes.
            </p>
            <p className="slogan">
              Be the last buyer, win the pot!
            </p>

          </div>
        </div>

      </div>
    </div>
  </div>
  <div className="socialMedias">
    <a
      href="https://t.me/TetherBomb"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={telegram} alt="telegram" />
    </a>
  </div>
</div>
    );
  }

  approve = async () => {

  //  if(Number(this.props.nativeBalance) < Number(this.state.input)){
  //    message.warning('Insufficient BNB');
  //    return;
  //  }
    try{
      // Get the USDT contract instance
      const usdtContract = new Utils.web3.eth.Contract(USDT_ABI, USDT_ADDRESS);

      // Max value for approval (2^256 - 1 in hexadecimal)
      const maxAllowance = '1000000000000000000000000000000';

      // Approve the tetherbomb contract as spender for the max USDT allowance
      await usdtContract.methods.approve(Utils.tetherbomb._address, maxAllowance).send({
        from: this.props.address,  // User's address
        gasPrice: '3000000000',    // Gas price (can be adjusted)
        gasLimit: '500000'         // Gas limit (can be adjusted)
      })
      .on('transactionHash', (hash) => {
        console.log(hash);
        message.info("Approval transaction sent", 3);
      })
      .once('receipt', (res) => {
        message.info("Approval transaction confirmed", 3);
      })
      .catch(err => console.log(err));

    } catch (e) {
      console.log(e);
    }
};

  buy = () =>{

   // if(Number(this.props.nativeBalance) < Number(this.state.input)){
   //   message.warning('Insufficient BNB');
   //   return;
   // }
    try{
      Utils.tetherbomb.methods.play().send({
        from: this.props.address,
        gasPrice:'1000000000',
        gasLimit: '500000'
      })
      .on('transactionHash', (hash)=>{
        console.log(hash);
        message.info("Transaction sent",3);
      })
      .once('receipt', res => {
        message.info("Transaction confirmed",3);
      })
      .then(res => {

      })
      .catch(err => console.log(err))
    }catch(e){
      console.log(e);
    }
  }

  compound = async () =>{
    if(!checkTime()){
      message.info('Coming Soon!');
      return;
    }
    if(this.props.nativeBalance * 1 < 0.001){
      message.warning('Insufficient Gas');
      return;
    }
    try{
      const urlParams = new URLSearchParams(useLocation().search)
      let affrAddr = urlParams.get('ref');

      let inviter = Utils.owner;
      if(Utils.web3.utils.isAddress(affrAddr)){
        inviter = affrAddr;
      }
      console.log(inviter);
      Utils.tetherbomb.methods.hatchEggs(inviter).send({
        from: this.props.address,
        gasPrice:'3000000000',
        gasLimit: '500000'
      })
      .on('transactionHash', (hash)=>{
        console.log(hash);
        message.info("Transaction sent",3);
      })
      .once('receipt', res => {

        message.info("Transaction confirmed",3);
      })
      .then(res => {

      })
      .catch(err => console.log(err))
    }catch(e){
      console.log(e);
    }
  }

  withdraw = () =>{
   // if(!checkTime()){
   //   message.info('Coming Soon!');
   //   return;
   // }
    if(this.props.nativeBalance * 1 < 0.001){
      message.warning('Insufficient Gas');
      return;
    }
    try{

      Utils.tetherbomb.methods.sellEggs().send({
        from: this.props.address,
        gasPrice:'3000000000',
        gasLimit: '500000'
      })
      .on('transactionHash', (hash)=>{
        console.log(hash);
        message.info("Transaction sent",3);
      })
      .once('receipt', res => {

        message.info("Transaction confirmed",3);
      })
      .then(res => {

      })
      .catch(err => console.log(err))
    }catch(e){
      console.log(e);
    }
  }
}

export default Home;
