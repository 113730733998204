import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./views/Home/Home";
import { Utils } from "./utils/utils";  // Adjust this import as needed
import USDT_ABI from "../src/utils/usdtABI.json";
import BN from 'bn.js';

const USDT_ADDRESS = '0x55d398326f99059fF775485246999027B3197955';

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nativeBalance: 0,
      contractBalance: 0,
      timeRemaining: 0,
      winner: '',
      userUSDT: 0,
    };
  }

  async componentDidMount() {
    this.checkContract();
    this.timer = setInterval(() => {
      this.checkContract();
    }, 10000);  // Change to 10000 for 10 seconds
  }

  componentWillUnmount() {
    clearInterval(this.timer); // Clear the timer on unmount
    clearTimeout(this.timeout); // Clear the timeout if it's set
  }

  checkContract = () => {
    if (Utils.web3 && Utils.tetherbomb && this.props.address) {
      clearInterval(this.timer);
      this.getContractStats();
    }
  };

  getContractStats = async () => {
    clearTimeout(this.timeout);
    try {
      // Get native BNB balance
      const nativeBalance = await Utils.web3.eth.getBalance(this.props.address);
      console.log("User BNB:",nativeBalance)
      const nativeBalanceBN = new BN(nativeBalance);
      const formattedNativeBalance = Utils.web3.utils.fromWei(nativeBalanceBN, 'ether');
      console.log("User BNB:",formattedNativeBalance)

      // Get USDT balance using the USDT contract's balanceOf method
      const usdtContract = new Utils.web3.eth.Contract(USDT_ABI, USDT_ADDRESS);

      // Get Pot Size
      const usdtBalance = await usdtContract.methods.balanceOf(Utils.tetherbomb._address).call();
      console.log("Pot Size:",usdtBalance)

      // Convert USDT balance using BN.js
      const usdtBN = new BN(usdtBalance);
      const contractBalance = usdtBN.div(new BN(10).pow(new BN(18))).toString(); // Assuming USDT has 6 decimals

      // Get Pot Size
      const userUSDTRaw = await usdtContract.methods.balanceOf(this.props.address).call();

      // Convert USDT balance using BN.js
      const usdtUserBN = new BN(userUSDTRaw);
      const userUSDT = usdtUserBN.div(new BN(10).pow(new BN(18))).toString(); // Assuming USDT has 6 decimals
      console.log("User USDT:",userUSDT)

      // Get timeRemaining and lastDepositor from the contract
      const timeRemaining = Number(await Utils.tetherbomb.methods.timeRemaining().call());
      const winner = await Utils.tetherbomb.methods.lastDepositor().call();
  
      // Set the state
      this.setState({
        contractBalance: contractBalance,
        nativeBalance: formattedNativeBalance,
        userUSDT: userUSDT,
        timeRemaining: timeRemaining,
        winner: winner,
      });
    } catch (e) {
      console.error("Error fetching contract stats:", e);
    }
  
    this.timeout = setTimeout(() => {
      this.getContractStats();
    }, 10000); // Refresh every 10 seconds
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Home
              address={this.props.address}
              nativeBalance={this.state.nativeBalance}
              timeRemaining={this.state.timeRemaining}
              winner={this.state.winner}
              userUSDT={this.state.userUSDT}
              contractBalance={this.state.contractBalance}

            />
          )}
        />

        <Route
          render={() => (
            <Home
            address={this.props.address}
            nativeBalance={this.state.nativeBalance}
            timeRemaining={this.state.timeRemaining}
            winner={this.state.winner}
            userUSDT={this.state.userUSDT}
            contractBalance={this.state.contractBalance}

            />
          )}
        />
      </Switch>
    );
  }
}

export default Routes;
